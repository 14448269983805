*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 105px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

:root {
  --primary-color: #0d2f3f;
  --secondary-color: #286f6c;
  --secondary-white-color: #e7e7e7;

  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  --xxl: 1536;
}

body {
  font-family: "Eudoxus Sans", sans-serif;
  /* overflow: hidden; */
  overflow: clip auto;
  background-color: #f8f7f1;
  /* height: 5000px; */
}

/* HEADER */

header {
  position: sticky;
  z-index: 99;
  top: 0;
  width: 100%;
  padding: 32px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f7f1;
  box-shadow: none;
  transition: box-shadow 0.1s;
}

header .logo {
  width: 135px;
}

header nav ul.big-screen-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

header nav ul.big-screen-nav li {
  padding: 0 16px;
}

header nav ul li a {
  color: var(--primary-color);
  font-weight: 500;
}
header nav ul li a:hover {
  color: var(--secondary-color);
}

header nav ul li:last-child a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

header nav ul li:last-child a img {
  padding: 12px;
  background-color: #fff;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  box-shadow: 0 4px 4px #0000000d;
}

header nav button {
  display: none;
}

@media (width <= 1066px) {
  header {
    padding: 32px 32px;
  }

  .big-screen-nav {
    display: none !important;
  }

  header nav button {
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .small-screen-nav {
    display: flex !important;
  }
}

.small-screen-nav {
  display: none;
  position: absolute;
  right: -260px;
  top: 112px;
  height: calc(100vh - 112.14px);
  border-radius: 9px 0 0 9px;
  background-color: #fff;
  /* background-color: red; */
  transition: 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0 4px 4px #0000000d;
}

.small-screen-nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.small-screen-nav ul li {
  padding: 18px 16px;
}

.show-hide-nav {
  right: 0 !important;
}

.mobile-nav-footer div {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mobile-nav-footer div a {
  transition: 0.1s;
}

.mobile-nav-footer div a:hover {
  scale: 1.1;
}

.mobile-nav-footer p {
  font-size: 12px;
  color: var(--primary-color);
}

/* HERO */
.hero {
  padding: 32px 64px 0 64px;
  width: 100%;
  height: calc(100vh - 104px);
  background-color: #f8f7f1;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-image: url(./assets/image/splash.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  color: #0d2f3f;
}

.left-hero,
.person-box,
.right-hero {
  width: calc(100% / 3);
}

.left-hero {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  padding: 64px 0;
}

.left-hero h1 {
  font-size: 48px;
  font-weight: 700;
}

.years-experience-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.ten {
  font-size: 63px;
  font-weight: 700;
}

.years-experience {
  font-size: 25px;
  font-weight: 700;
}

.person-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-hero {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 64px 0;
}

.right-hero div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-hero img {
  width: 70px;
  margin-bottom: 16px;
}

.right-hero h2 {
  font-size: 25px;
  font-weight: 700;
}

.alt-right-hero {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: #f8f7f1;
}

.alt-right-hero div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alt-right-hero img {
  width: 70px;
  margin-bottom: 16px;
}

.alt-right-hero h2 {
  font-size: 25px;
  font-weight: 700;
}

@media (width <= 1066px) {
  .hero {
    padding: 32px 32px 0 32px;
    flex-direction: column;
    align-items: center;
  }

  .left-hero {
    flex-direction: row;
    padding: 0;
    width: 100%;
  }

  .right-hero {
    display: none;
  }

  .alt-right-hero {
    display: flex;
  }

  .alt-right-hero div {
    flex-direction: row;
    gap: 12px;
  }

  .alt-right-hero img {
    margin: 0;
  }
}

@media (width <= 575px) {
  .left-hero {
    flex-direction: column;
    text-align: center;
  }

  .left-hero h1 {
    width: 100%;
  }

  .years-experience-box {
    display: none;
  }

  .alt-right-hero img {
    width: 60px;
  }

  .alt-right-hero h2 {
    font-size: 20px;
  }
}

/* Services */
#services {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 64px;
  gap: 32px;
  color: #0d2f3f;
  /* scroll-snap-align: start; */
}

#services > div {
  width: 50%;
}

.left-services {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.left-services > div {
  border: 1px solid #e7e7e7;
  width: 100%;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  align-items: center;
  gap: 32px;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.2s;
}

.left-services > div h3 {
  font-weight: 500;
}

.left-services > div div:first-of-type {
  border-radius: 50%;
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-services > div .image-box-one {
  background-color: #286f6c;
}
.left-services > div .image-box-two {
  background-color: #eec048;
}
.left-services > div .image-box-three {
  background-color: #f26440;
}

.services-title {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.left-services > div:hover {
  box-shadow: 0 21px 13px #0000000a;
}

.right-services {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.right-services h1 {
  font-size: 48px;
}

.right-services p {
  font-size: 22px;
  font-weight: 500;
  line-height: 40px;
}

.right-services > div {
  display: flex;
  gap: 64px;
  text-align: center;
}

.right-services > div h2 {
  font-size: 48px;
  font-weight: 700px;
}

.right-services > div h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 50px;
}

@media (width <= 915px) {
  #services {
    padding: 32px;
    flex-wrap: wrap;
    gap: 64px;
  }

  #services > div {
    width: 100%;
  }

  .right-services > div div {
    /* justify-content: center; */
    width: 50%;
  }

  .right-services h1 {
    font-size: 43px;
  }
}

/* Experience */
main.experience {
  background-color: #f8f7f1;
  color: #0d2f3f;
  padding: 64px;
}

.experience h1 {
  font-size: 48px;
  font-weight: 700;
  width: fit-content;
  margin: auto;
  margin-bottom: 96px;
}

.experience h2 {
  font-size: 24px;
}

.experience p {
  font-size: 16px;
  line-height: 40px;
}

.article-experience {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* gap: 32px; */
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 1.4rem;
}

.section-experience {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 32px;
}

.section-experience div {
  width: 50%;
}

.progressbar {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4rem;
  left: 45%;
  top: 7px;
}

.line {
  position: absolute;
  height: 92%;
  width: 2px;
  background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 2px 30px;
  background-repeat: repeat-y;
  left: 48%;
  z-index: 1;
  border-radius: 5px;
}

.progressbar > div {
  flex: 1;
  /* padding-top: 0.8rem; */
  z-index: 2;
}

.circle {
  border-radius: 50%;
  background: black;
  width: 1.2rem;
  height: 1.2rem;
}

@media (width <= 991px) {
  main.experience {
    padding: 32px;
  }
}

/* PORTFOLIO */

#portfolio {
  padding: 64px;
  color: #0d2f3f;
  background-color: #fff;
}

#portfolio h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 12px;
}

#portfolio > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

#portfolio div a {
  color: orange;
  text-decoration: underline;
  font-size: 21px;
  font-weight: 500;
}

#portfolio p {
  font-size: 16px;
  font-weight: 500;
}

#portfolio section {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  gap: 35px;
  width: 100%;
  flex-wrap: wrap;
}

#portfolio section a {
  /* flex: 1; */
  /* width: calc((100% - 70px) / 3); */
  cursor: pointer;
  box-shadow: 0 21px 13px #00000021;
  border-radius: 20px;
  overflow: hidden;

  max-width: 23rem;
  max-height: 18rem;
}

#portfolio section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (width <= 650px) {
  #portfolio {
    padding: 32px;
  }

  #portfolio > div {
    flex-direction: column;
    gap: 12px;
  }

  #portfolio h1 {
    font-size: 40px;
  }
}

/* Testimonials */
#testimonials {
  padding: 64px;
  background-color: #f8f7f1;
  color: #0d2f3f;
}

#testimonials h1 {
  font-size: 48px;
  width: fit-content;
  margin: auto;
  margin-bottom: 32px;
}
#testimonials > p {
  width: fit-content;
  margin: auto;
  text-align: center;
  line-height: 30px;
  margin-bottom: 64px;
  font-size: 16px;
}

#testimonials .comments p {
  width: fit-content;
  margin: auto;
  text-align: center;
  line-height: 25px;
  margin-bottom: 16px;
  font-size: 15px;
}

#testimonials h3 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

#testimonials hr {
  border: none;
  border-radius: 20px;
  margin-bottom: 5px;
  background-color: rgb(234, 234, 234);
  width: 100%;
  height: 2px;
  margin-bottom: 16px;
}

#testimonials h6 {
  font-weight: 500;
  font-size: 0.7rem;
  margin-bottom: 5px;
  color: #acacac;
}

#testimonials .testtimonial-card {
  background-color: #fff;
  /* border: 1px solid; */
  border-radius: 20px;
  box-shadow: #0000000d -3px 11px 13px;
  padding: 32px;
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#testimonials .testtimonial-card img {
  position: absolute;
  top: -44px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: #0000000d -3px 11px 13px;
}

.slick-track {
  display: flex !important;
  gap: 32px;
}

.slick-list {
  padding: 100px 0 64px !important;
}

.slick-slide {
  transition: 0.3s ease-out;
}

.slick-slide.slick-active.slick-current + .slick-slide {
  @media (min-width: 1024px) {
    scale: 1.1;
    transform: translateY(-30px);
  }
}

@media (width <= 991px) {
  #testimonials {
    padding: 32px;
  }

  #testimonials > p {
    margin-bottom: 0px;
  }
}

/* Footer */

footer {
  padding: 64px;
  display: flex;
  align-items: center;
  /* gap: 36px; */
  color: #0d2f3f;
  background-color: #fff;
}

footer .footer-slogan {
  width: 75%;
}

footer h1 {
  font-size: 48px;
  font-weight: 700;
}

footer div h1:first-of-type {
  margin-bottom: 48px;
}

footer div h1:nth-of-type(2) a {
  color: orange;
  text-decoration: underline;
}

footer div h2 {
  margin-bottom: 12px;
}

footer .footer-link {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

footer a {
  color: inherit;
  width: fit-content;
}

footer a:hover {
  color: #286f6c;
}

@media (width <= 915px) {
  footer {
    padding: 32px;
    flex-wrap: wrap;
  }

  footer .footer-slogan {
    width: 100%;
  }

  footer .footer-slogan {
    margin-bottom: 48px;
  }

  footer .footer-link {
    flex-direction: row;
  }

  footer div h1:nth-child(2) {
    font-size: 36px;
  }
}
